/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * Site header and navbar
 */

import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/node';
import axios, { AxiosResponse } from 'axios';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useSelector } from 'react-redux';
import db from '../../SDKs/firebase/firebase';
import { useFranchiseeContext } from '../../context/Franchisee';
import { MemoizedBookingStorePicker } from '../BookingStorePicker';
import ContentDrawer from '../ContentDrawer';
// import DynamicPageDropdown from '../DynamicPageDropdown';
import PhoneLink from '../PhoneLink';

const Header: React.FC = () => {
  const {
    phonePrimary,
    reviewFacebookUrl,
    reviewGooglePlusUrl,
    name: franchiseeName,
    id: franchiseeId,
    slug: franchiseeSlug,
    url: franchiseeUrl,
    careerOpportunities: franchiseeCareerOpportunities,
  } = useFranchiseeContext().franchisee;

  const router = useRouter();

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [showStorePicker, setShowStorePicker] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [franchiseeSelection, setFranchiseeSelection] = useState<string>('');
  const [mobileNavbarActive, setMobileNavbarActive] = useState<boolean>(false);

  const funnelPages = ['/book', '/schedule', '/confirm'];

  const handleOnIdle = (event) => {
    axios
      .get('/api/lead-session/')
      .then((res) => {
        const lead = res.data.data.lead.id;
        const userStatusDatabaseRef = db.ref(`/online/${lead}`);
        userStatusDatabaseRef.remove();
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 60000,
    onIdle: handleOnIdle,
  });

  const firstRender = useRef(false);
  const secondRender = useRef(false);

  const closeDrawer = (event) => {
    if (!event.target.closest('.content-drawer')) {
      setShowDrawer(false);
    }
  };

  const closeStorePicker = (event) => {
    if (!event.target.closest('.modal-content')) {
      setShowStorePicker(false);
    }
  };
  const closeExpandedNav = (event) => {
    if (!event.target.closest('.header')) {
      setMobileNavbarActive(false);
    }
  };
  const DynamicStorePicker = dynamic(() => import('../StorePicker'), {
    ssr: false,
  });

  useEffect(() => {
    // handle closing the drawer if they click outside of it.
    document.addEventListener('click', closeDrawer);
    document.addEventListener('click', closeStorePicker);
    document.addEventListener('click', closeExpandedNav);

    return () => {
      // unmounts the event listener when the component is unmounted;
      document.removeEventListener('click', closeDrawer);
      document.removeEventListener('click', closeStorePicker);
    };
  }, []);

  // useEffect(() => {
  //   if (router.query.locationChange) {
  //     setShowDrawer(true);
  //     router.replace(`/${franchiseeSlug}`, undefined, { shallow: true });
  //   }
  // }, [router]);

  useEffect(() => {
    if (
      router.pathname === '/' &&
      franchiseeSlug !== 'home' &&
      franchiseeName !== 'Home'
    ) {
      router.push(`/${franchiseeSlug}`);
    } else if (
      router.pathname === '/[franchiseeSlug]' &&
      sessionStorage.getItem('selectedStore') !== franchiseeSlug &&
      sessionStorage.getItem('selectedStore') !== null
    ) {
      // router.push(`/${sessionStorage.getItem('selectedStore')}`);
      window.location.href = `/${sessionStorage.getItem('selectedStore')}`;
    }
  }, [franchiseeSlug]);

  useEffect(() => {
    setTimeout(() => {
      if (firstRender.current && !secondRender.current) {
        setPhoneNumber('(800) 355-3552');
      } else {
        if (phonePrimary === '8003553552') return;
        setPhoneNumber(phonePrimary);
      }
    }, 1000);
  }, [firstRender, secondRender, phonePrimary]);

  useEffect(() => {
    if (phonePrimary === '8003553552') {
      firstRender.current = true;
      return;
    }
    if (phonePrimary !== '8003553552') {
      secondRender.current = true;
    }
  }, [phonePrimary]);

  useEffect(() => {
    setTimeout(() => {
      if (firstRender.current && !secondRender.current) {
        setFranchiseeSelection('Enter Your Zip Code');
      } else {
        if (franchiseeName === 'Home') return;
        setFranchiseeSelection(franchiseeName);
      }
    }, 1000);
  }, [firstRender, secondRender, franchiseeName]);

  useEffect(() => {
    if (franchiseeName === 'Home') {
      firstRender.current = true;
      return;
    }
    if (franchiseeName !== 'Home') {
      secondRender.current = true;
    }
  }, [franchiseeName]);

  function extractFromAddress(components, type) {
    return (
      components
        .filter((component) => component.types.indexOf(type) === 0)
        .map((item) => item.long_name)
        .pop() || null
    );
  }
  const getLocationFromGoogle = async (position) => {
    const { latitude, longitude } = position.coords;
    try {
      const data: AxiosResponse = await axios(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&components=postal_code&key=${process.env.GOOGLE_GEO_API_KEY}`
      );
      const googleResults = data.data;
      let state = '';
      let city = '';
      let zipCode = '';
      if (googleResults.results[0]) {
        state = extractFromAddress(
          googleResults.results[0].address_components,
          'administrative_area_level_1'
        );
        zipCode = extractFromAddress(
          googleResults.results[0].address_components,
          'postal_code'
        );
        city = extractFromAddress(
          googleResults.results[0].address_components,
          'locality'
        );
      }

      const response: AxiosResponse = await axios.post(
        '/api/user-location-session/',
        {
          userLocation: { state, city, zipCode, latitude, longitude },
        }
      );
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  const getLocationFromIP = async () => {
    try {
      const result: AxiosResponse = await axios(
        `https://api.ipstack.com/check?access_key=${process.env.IPSTACK_ACCESS_KEY}`
      );

      const state = result.data.region_code;
      const city = result.data.city;
      const zipCode = result.data.zip;
      const ip = result.data.ip;
      let latitude = 0;
      let longitude = 0;
      if (
        result.data.hasOwnProperty('latitude') &&
        result.data.hasOwnProperty('longitude')
      ) {
        latitude = result.data.latitude;
        longitude = result.data.longitude;
      }
      const response: AxiosResponse = await axios.post(
        '/api/user-location-session/',
        {
          userLocation: { state, city, zipCode, ip, latitude, longitude },
        }
      );
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    async function getZipcode() {
      try {
        const userLocationData = await axios.get('/api/user-location-session/');
        const user_location_response = userLocationData.data;
        if (user_location_response?.data?.userLocation?.zipCode?.length === 5)
          return;
        getLocationFromIP();
        // if (user_location_response.message.includes('Error')) {
        //   if (navigator.geolocation) {
        //     setTimeout(() => {
        //       navigator.geolocation.getCurrentPosition(
        //         getLocationFromGoogle,
        //         getLocationFromIP
        //       );
        //     }, 3000);
        //   }
        // }
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      }
    }
    if (window?.location?.href.includes('gratuity')) return;
    getZipcode();
  }, []);

  useEffect(() => {
    async function getUTM() {
      let utmSource = '';
      let utmMedium = '';
      let utmCampaign = '';
      if (window?.location?.href.includes('utm_source')) {
        utmSource = window.location.href.split('utm_source=')[1].split('&')[0];
        utmMedium = window.location.href.split('utm_medium=')[1].split('&')[0];
        utmCampaign = window.location.href
          .split('utm_campaign=')[1]
          .split('&')[0];
      } else return;
      try {
        const response: AxiosResponse = await axios.post(
          '/api/utm-tracking-session/',
          {
            utmTracking: { utmSource, utmMedium, utmCampaign },
          }
        );
      } catch (error) {
        // console.log(error);
        Sentry.captureException(error);
      }
    }
    getUTM();
  }, []);

  // Used to retrieve the google tag manager src
  useEffect(() => {
    axios
      .post('/api/get-locations/')
      .then((res) => {
        const result = res.data.data.find(
          (franchise) => franchise.slug === franchiseeSlug
        );

        // Sets Google Tracking ID as a variable
        let franchiseTrackingId =
          result &&
          result.google_analytics_html.substring(
            result.google_analytics_html.indexOf('src=') + 49,
            result.google_analytics_html.indexOf(`">`)
          );

        const scripts = document.querySelectorAll('script');

        let hasGtagFunc = false;
        scripts.forEach((script) => {
          if (script.innerHTML.includes('gtag')) {
            hasGtagFunc = true;
          }

          if (
            script.src.includes('https://www.google-analytics.com/analytics.js')
          ) {
            script.remove();
          }
        });

        if (
          hasGtagFunc === false &&
          process.env.TWOMAIDS_ENV === 'production'
        ) {
          if (typeof franchiseTrackingId !== 'undefined') {
            const gtagScript = document.createElement('script');
            gtagScript.innerHTML = `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${franchiseTrackingId}', { page_path: window.location.pathname });`;
            // document.head.prepend(gtagScript);

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtag/js?id=${franchiseTrackingId}`;
            // document.head.prepend(script);
          }
        }

        if (franchiseTrackingId) {
          axios
            .post('/api/tracking-session/', { franchiseTrackingId })
            .then((res) => {
              // console.log(res);
            });
        } else {
          franchiseTrackingId = null;
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post('/api/get-locations/')
      .then((res) => {
        const result = res.data.data.find(
          (franchise) => franchise.slug === franchiseeSlug
        );

        let mySubString;

        // Gets the full source for the first Google Script Tag
        mySubString =
          result &&
          result.google_analytics_html.substring(
            result.google_analytics_html.indexOf('src=') + 5,
            result.google_analytics_html.indexOf(`">`)
          );
        createScript();

        // let mySecondSubString = result.google_analytics_html.substring(
        //   result.google_analytics_html.indexOf(`Google Tag Manager`) + 32,
        //   result.google_analytics_html.indexOf(`GTM`) + 14
        // );

        // if (mySecondSubString.includes('GTM-')) {
        //   createScript(mySecondSubString);
        // }
        // console.log('mySecondSubString', mySecondSubString);
        // console.log('mySubString', mySubString);

        const scripts = document.querySelectorAll('script');
        let googleScriptFound = false;
        scripts.forEach((script) => {
          if (
            script.src.includes('googletag') &&
            script.src.includes('dataLayer') == false
          ) {
            googleScriptFound = true;
            script.remove();
          }
        });
        // Sets Google Tracking ID as a variable
        let franchiseTrackingId =
          result &&
          result.google_analytics_html.substring(
            result.google_analytics_html.indexOf('src=') + 49,
            result.google_analytics_html.indexOf(`">`)
          );

        let hasGtagFunc = false;
        scripts.forEach((script) => {
          if (
            script.innerHTML.includes(`gtag`) ||
            script.src.includes('undefined') ||
            script.src.includes('https://www.google-analytics.com/analytics.js')
          ) {
            hasGtagFunc = true;
            // script.remove();
          }
        });

        if (true) {
          const analyticsScript = document.createElement('script');
          analyticsScript.src = 'https://www.google-analytics.com/analytics.js';
          document.head.prepend(analyticsScript);

          if (typeof franchiseTrackingId !== 'undefined') {
            const updatedTrackingFunction = document.createElement('script');
            updatedTrackingFunction.innerHTML = `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${franchiseTrackingId}', { page_path: window.location.pathname });`;
            // document.head.prepend(updatedTrackingFunction);

            const updatedTrackingScript = document.createElement('script');
            updatedTrackingScript.src = mySubString;

            if (typeof mySubString === 'undefined') {
              return;
            }

            // document.head.prepend(updatedTrackingScript);
          }
        }

        if (franchiseTrackingId) {
          axios
            .post('/api/tracking-session/', { franchiseTrackingId })
            .then((res) => {
              // console.log(res);
            });
        } else {
          franchiseTrackingId = null;
        }
      })
      .catch((err) => console.log(err));
  }, [franchiseeSlug]);

  const createScript = () => {
    try {
      const id = 'gtag';
      const id2 = 'gtag2';
      const id3 = 'gtag3';
      const id4 = 'gtag4';
      const id5 = 'gtag5';

      if (
        // document.getElementById(id) === null &&
        document.getElementById(id2) === null &&
        // document.getElementById(id3) === null &&
        document.getElementById(id4) === null &&
        document.getElementById(id5) === null
      ) {
        // var scriptElm = document.createElement('script');
        const scriptElm2 = document.createElement('script');
        // var scriptElm3 = document.createElement('script');
        const scriptElm4 = document.createElement('script');
        const scriptElm5 = document.createElement('script');

        // scriptElm3.src =
        //   'https://www.googletagmanager.com/gtag/js?id=AW-10889637433';
        // scriptElm3.async = true;
        //   var inlineCode =
        //     document.createTextNode(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        // new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        // j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        // 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        // })(window,document,'script','dataLayer','GTM-MWFJ3JZ');`);
        const inlineCode2 =
          document.createTextNode(` window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());gtag('config', 'AW-10889637433');`);
        const inlineCode3 = document.createTextNode(
          `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-MWFJ3JZ');`
        );
        // var inlineCode5 = document.createTextNode(
        //   `(function(i,n,v,o,c,a) { i.InvocaTagId = o; var s = n.createElement('script'); s.type = 'text/javascript';
        //   s.async = true; s.src = ('https:' === n.location.protocol ? 'https://' : 'http://' ) + v;
        //   var fs = n.getElementsByTagName('script')[0]; fs.parentNode.insertBefore(s, fs);
        // })(window, document, 'solutions.invocacdn.com/js/invoca-latest.min.js', '2329/1629673968');`
        // );
        // scriptElm.appendChild(inlineCode);
        scriptElm2.appendChild(inlineCode2);
        scriptElm4.appendChild(inlineCode3);
        // scriptElm5.appendChild(inlineCode5);

        // scriptElm.setAttribute('id', id);
        scriptElm2.setAttribute('id', id2);
        // scriptElm3.setAttribute('id', id3);
        scriptElm4.setAttribute('id', id4);
        scriptElm5.setAttribute('id', id5);

        // document.head.prepend(scriptElm);
        document.head.prepend(scriptElm2);
        // document.head.prepend(scriptElm3);
        // document.head.prepend(scriptElm4);
        // document.head.prepend(scriptElm5);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section
        id='header'
        className='navigation header'
        style={{
          position: router.pathname === '/book' ? 'sticky' : 'relative',
        }}
      >
        <div className='top-nav'>
          <div className='top-nav-left'>
            Your store{' '}
            {router.pathname.match(/book|schedule|confirm|pre-open/g) ? (
              <span style={{ cursor: 'not-allowed' }}>
                {franchiseeSelection === '' ? (
                  <span
                    className='shimmer-container'
                    style={{ marginLeft: '5px' }}
                  >
                    <span
                      className='shimmer gradient'
                      style={{ padding: `1px 50px` }}
                    ></span>
                  </span>
                ) : (
                  <span>{franchiseeSelection.toUpperCase()}</span>
                )}
              </span>
            ) : (
              <span
                onClick={(e) => {
                  e.preventDefault();
                  navigator.geolocation.getCurrentPosition(
                    getLocationFromGoogle,
                    getLocationFromIP
                  );
                  e.stopPropagation();
                  setShowDrawer(!showDrawer);
                }}
              >
                {franchiseeSelection === '' ? (
                  <span
                    className='shimmer-container'
                    style={{ marginLeft: '5px' }}
                  >
                    <span
                      className='shimmer gradient'
                      style={{ padding: `1px 50px` }}
                    ></span>
                  </span>
                ) : (
                  <span>{franchiseeSelection.toUpperCase()}</span>
                )}
              </span>
            )}
          </div>
          <div className='top-nav-right'>
            <div>
              {/* <li className='header-link-li-mobile'> */}
              <Link href='/book'>
                <a
                  className='header-link-booking'
                  aria-label={`Book Your Cleaning`}
                  style={{
                    color: router.pathname.match('/') ? '#000' : '',
                  }}
                >
                  BOOK YOUR CLEANING
                </a>
              </Link>
              {/* </li> */}
            </div>
            <div>
              Call for a Free Estimate!{' '}
              <span>
                {phoneNumber === '' ? (
                  <span className='shimmer-container'>
                    <span
                      className='shimmer gradient'
                      style={{ padding: `1px 47px` }}
                    >
                      &nbsp;
                    </span>
                  </span>
                ) : (
                  <PhoneLink number={phoneNumber} />
                )}
              </span>
            </div>
          </div>
        </div>
        <hr />
        <div className='nav-container'>
          <div className='brand'>
            <Link href='/'>
              <a>
                <Image
                  src='https://res.cloudinary.com/twomaidsengi/image/upload/v1707161996/hubsite-reskin/two-maids-logo-pink.webp'
                  layout='fill'
                  objectPosition='left'
                  alt='Pink Two Maids logo'
                  // objectFit='contain'
                  // sizes='(max-width: 1192px) 150px, 175px'
                  width={3405}
                  height={722}
                />
              </a>
            </Link>
          </div>
          <nav
            style={{
              padding: funnelPages.includes(router.pathname)
                ? '0px'
                : 'initial',
              width: funnelPages.includes(router.pathname) ? 'auto' : 'auto',
              fontSize: '12px',
            }}
            className={` ${funnelPages.includes(router.pathname) ? 'nav-funnel' : ''
              }`}
          >
            <div
              className={`nav-mobile`}
              style={{
                display: funnelPages.includes(router.pathname) ? 'none' : '',
              }}
            >
              <button
                id='nav-toggle'
                onClick={() => setMobileNavbarActive(!mobileNavbarActive)}
                className={`${mobileNavbarActive ? 'active' : ''}`}
                aria-label='Navigation Toggle'
              >
                <span></span>
              </button>
            </div>
            {funnelPages.includes(router.pathname) ? (
              <div style={{ marginRight: '30px', textAlign: 'right' }}>
                Call for a Free Estimate!{' '}
                <div>
                  {phoneNumber === '' ? (
                    <span className='shimmer-container'>
                      <span className='shimmer gradient'>&nbsp;</span>
                    </span>
                  ) : (
                    <PhoneLink number={phoneNumber} />
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            <ul
              className='nav-list'
              style={{ display: mobileNavbarActive ? 'block' : 'none' }}
            >
              {!mobileNavbarActive ?
                <li className='dropdown'>
                  <Link href='/services/' passHref prefetch={false}>
                    <a
                      className='header-link-reskin'
                      aria-label='Services'
                      style={{
                        color: router.pathname.match('/services') ? '#000' : '',
                        display: funnelPages.includes(router.pathname)
                          ? 'none'
                          : 'block',
                      }}
                    >
                      Services <i className='fa fa-caret-down'></i>
                    </a>
                  </Link>
                  <ul className='dropdown-content'>
                    {/* Put your dropdown links here */}
                    <li>
                      <Link
                        href="/services/recurring-clean"
                        passHref
                        prefetch={false}
                      >
                        <a>Recurring Cleaning</a>
                      </Link>
                    </li>
                    <li></li>
                    <li>
                      <Link
                        href="/services/one-time-clean"
                        prefetch={false}
                      >
                        <a>One-Time Cleaning</a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="/services/move-in-move-out"
                        passHref
                        prefetch={false}
                      >
                        <a>Move-in/Move-out</a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="/services/deep-clean"
                      >
                        <a>Deep Cleaning</a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="/services/apartment-clean"
                      >
                        <a>Apartment Cleaning</a>
                      </Link>
                    </li>
                  </ul>
                </li>
                :
                <>
                  <li>
                    <Link href='/services/' passHref prefetch={false}>
                      <a
                        className='header-link-reskin'
                        aria-label='Services'
                        style={{
                          color: router.pathname.match('/services') ? '#000' : '',
                          display: funnelPages.includes(router.pathname)
                            ? 'none'
                            : 'block',
                        }}
                      >
                        Services
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/services/recurring-clean"
                      passHref
                      prefetch={false}
                    >
                      <a>Recurring Cleaning</a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/services/one-time-clean"
                      prefetch={false}
                    >
                      <a>One-Time Cleaning</a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/services/move-in-move-out"
                      passHref
                      prefetch={false}
                    >
                      <a>Move-in/Move-out</a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/services/deep-clean"
                    >
                      <a>Deep Cleaning</a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/services/apartment-clean"
                    >
                      <a>Apartment Cleaning</a>
                    </Link>
                  </li>
                </>
              }

              <li>
                <Link href='/locations/' passHref prefetch={false}>
                  <a
                    className='header-link-reskin'
                    aria-label='Locations'
                    style={{
                      color: router.pathname.match('/locations') ? '#000' : '',
                      display: funnelPages.includes(router.pathname)
                        ? 'none'
                        : 'block',
                    }}
                  >
                    Locations
                  </a>
                </Link>
              </li>
              <li>
                {' '}
                <Link href='/why-two-maids/' passHref prefetch={false}>
                  <a
                    className='header-link-reskin'
                    aria-label='Why Hire Us'
                    style={{
                      color: router.pathname.match('/why-two-maids')
                        ? '#000'
                        : '',
                      display: funnelPages.includes(router.pathname)
                        ? 'none'
                        : 'block',
                    }}
                  >
                    Why Hire Us
                  </a>
                </Link>
              </li>

              <li>
                {' '}
                <Link
                  href='https://twomaidsfranchise.com'
                  passHref
                  prefetch={false}
                >
                  <a
                    target='_blank'
                    className='header-link-reskin'
                    aria-label='Franchise Opportunities'
                  >
                    Franchise Opportunities
                  </a>
                </Link>
              </li>
              <li>
                <Link
                  href={
                    franchiseeId > -1
                      ? `/${franchiseeSlug}/careers/`
                      : '/careers/'
                  }
                  passHref
                  prefetch={false}
                >
                  <a> We're Hiring </a>
                </Link>
              </li>
              {/* These are the dropdown links ------------------------------- */}
              {!mobileNavbarActive && (
                <li className='dropdown'>
                  <a className='header-link-reskin' aria-label='More' href='#'>
                    More <i className='fa fa-caret-down'></i>
                  </a>
                  <ul className='dropdown-content'>
                    {/* Put your dropdown links here */}
                    <li>
                      <Link
                        href={
                          franchiseeId > -1
                            ? `/${franchiseeSlug}/about/`
                            : '/about/'
                        }
                        passHref
                        prefetch={false}
                      >
                        <a> About </a>
                      </Link>
                    </li>
                    <li></li>
                    <li>
                      <Link
                        href={`${franchiseeId > -1 ? '/' + franchiseeSlug : ''
                          }/blog/page/1`}
                        prefetch={false}
                      >
                        <a> Blog </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={
                          franchiseeId > -1
                            ? `/${franchiseeSlug}/reviews/`
                            : '/reviews/'
                        }
                        passHref
                        prefetch={false}
                      >
                        <a> Reviews </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={
                          franchiseeId > -1
                            ? `/${franchiseeSlug}/contact/`
                            : '/'
                        }
                      >
                        <a
                          onClick={(e) => {
                            if (franchiseeId === -1) {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowStorePicker(!showStorePicker);
                            }
                          }}
                          className='header-link-reskin'
                          aria-label={`Contact Us`}
                          style={{
                            color: router.pathname.match('/contact')
                              ? '#000'
                              : '',
                            display: funnelPages.includes(router.pathname)
                              ? 'none'
                              : 'block',
                          }}
                        >
                          Contact Us
                        </a>
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
              {mobileNavbarActive && (
                <>
                  <li>
                    <Link
                      href={
                        franchiseeId > -1
                          ? `/${franchiseeSlug}/about/`
                          : '/about/'
                      }
                      passHref
                      prefetch={false}
                    >
                      <a
                        className='header-link-reskin'
                        aria-label={'About'}
                        style={{
                          color: router.pathname.match('/about') ? '#000' : '',
                          display: funnelPages.includes(router.pathname)
                            ? 'none'
                            : 'block',
                        }}
                      >
                        About
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={
                        franchiseeId > -1
                          ? `/${franchiseeSlug}/careers/`
                          : '/careers/'
                      }
                      passHref
                      prefetch={false}
                    >
                      <a
                        className='header-link-reskin'
                        aria-label={`Careers`}
                        style={{
                          color: router.pathname.match('/careers')
                            ? '#000'
                            : '',
                          display: funnelPages.includes(router.pathname)
                            ? 'none'
                            : 'block',
                        }}
                      >
                        We're Hiring
                      </a>
                    </Link>
                  </li>
                  <li>
                    {' '}
                    <Link
                      href={`${franchiseeId > -1 ? '/' + franchiseeSlug : ''
                        }/blog/page/1`}
                      prefetch={false}
                    >
                      <a
                        className='header-link-reskin'
                        aria-label='Blog'
                        style={{
                          color: router.pathname.match('/blog') ? '#000' : '',
                          display: funnelPages.includes(router.pathname)
                            ? 'none'
                            : 'block',
                        }}
                      >
                        Blog
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={
                        franchiseeId > -1
                          ? `/${franchiseeSlug}/reviews/`
                          : '/reviews/'
                      }
                      passHref
                      prefetch={false}
                    >
                      <a
                        className='header-link-reskin'
                        aria-label='Reviews'
                        style={{
                          color: router.pathname.match('/reviews')
                            ? '#000'
                            : '',
                          display: funnelPages.includes(router.pathname)
                            ? 'none'
                            : 'block',
                        }}
                      >
                        Reviews
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={
                        franchiseeId > -1 ? `/${franchiseeSlug}/contact/` : '/'
                      }
                    >
                      <a
                        onClick={(e) => {
                          if (franchiseeId === -1) {
                            e.preventDefault();
                            e.stopPropagation();
                            setShowStorePicker(!showStorePicker);
                          }
                        }}
                        className='header-link-reskin'
                        aria-label={`Contact Us`}
                        style={{
                          color: router.pathname.match('/contact')
                            ? '#000'
                            : '',
                          display: funnelPages.includes(router.pathname)
                            ? 'none'
                            : 'block',
                        }}
                      >
                        Contact Us
                      </a>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </div>
        <div className='nav-bottom-mobile'>
          Your Store
          {router.pathname.match(/book|schedule|confirm|pre-open/g) ? (
            <span style={{ cursor: 'not-allowed' }}>
              {franchiseeSelection === '' ? (
                <span
                  className='shimmer-container'
                  style={{ marginLeft: '5px' }}
                >
                  <span
                    className='shimmer gradient'
                    style={{ padding: `1px 50px` }}
                  ></span>
                </span>
              ) : (
                <span> {franchiseeSelection.toUpperCase()}</span>
              )}
            </span>
          ) : (
            <span
              onClick={(e) => {
                e.preventDefault();
                navigator.geolocation.getCurrentPosition(
                  getLocationFromGoogle,
                  getLocationFromIP
                );
                e.stopPropagation();
                setShowDrawer(!showDrawer);
              }}
            >
              {franchiseeSelection === '' ? (
                <span
                  className='shimmer-container'
                  style={{ marginLeft: '5px' }}
                >
                  <span
                    className='shimmer gradient'
                    style={{ padding: `1px 50px` }}
                  ></span>
                </span>
              ) : (
                <span style={{ cursor: 'pointer' }}>
                  {franchiseeSelection.toUpperCase()}
                </span>
              )}
            </span>
          )}
        </div>
      </section>
      {showStorePicker && (
        <MemoizedBookingStorePicker
          showBookingModal={showStorePicker}
          handleShowStorePicker={(_, status) => setShowStorePicker(status)}
        />
      )}

      <ContentDrawer show={showDrawer} onClose={() => setShowDrawer(false)}>
        <DynamicStorePicker
          show={showDrawer}
          onSelect={() => setShowDrawer(false)}
        />
      </ContentDrawer>
    </>
  );
};

export default Header;