/**
* Our Color System
* Base on Material Designs naming converntion listed here:
*   https://css-tricks.com/what-do-you-name-color-variables/
*
* */

/* our Brand Color */
export const pink500 : string = '#D4357F'; // Primary
export const pink300 : string = '#ff8dd6';
export const pink50 : string = '#fff4f7';
export const pink700 : string = '#d73e85';
export const pink900 : string = '#832d50';

export const blue400 : string = '#1ab7f0'; // Primary
export const blue100 : string = '#c7eefc';
export const blue800 : string = '#0078b9';

export const white : string = '#fff';
export const gray100 : string = '#ECEFF1';
export const gray300 : string = '#d8d8d8';
export const gray800 : string = '#373737';

export const green200 : string = '#64ffbf';
export const green300 : string = '#14cf8f';
export const green500 : string = '#00b75e';
export const green800 : string = '#006c51';

export const red50 : string = '#ffeaef';
export const red400 : string = '#FF6961';
export const red600 : string = '#f71236';
export const red900 : string = '#ca001a';

export const yellow100 : string = '#fff9c3';
export const yellow700 : string = '#ffbe29';

export const brown700 : string = '#4c380b';

export const orange200 : string = '#ff9966'; // atomic tangerine
