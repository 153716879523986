import React, { createContext, useContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import camelcaseKeys from 'camelcase-keys';
import franchiseeReducer, {
  FranchiseeAction,
  INIT,
  REFRESH,
  REPLACE,
  CLEAR,
} from './franchiseeReducer';
import { FranchiseeState } from './franchisee.types';
// import Gataware from '../../SDKs/Gataware';
import defaultFranchisee from './defaultFranchisee.json';
import franchiseeSession from './franchiseeSession';
import axios from 'axios';

const FranchiseeContext = createContext(null);

export type FranchiseeContextHook = {
  franchisee: FranchiseeState;
  dispatchFranchisee: Function;
};

interface FranchiseeProviderProps {
  children: React.ReactNode;
  franchiseeValue?: FranchiseeState;
}

const FranchiseeProvider: React.FC<FranchiseeProviderProps> = ({
  children,
  franchiseeValue,
}: FranchiseeProviderProps) => {
  const [franchisee, dispatchFranchisee] = useImmerReducer<
    FranchiseeState,
    FranchiseeAction
  >(franchiseeReducer, franchiseeValue || defaultFranchisee);

  const refreshFranchisee = async () => {
    if (franchisee.id === -1) {
      const franIdResponse = await franchiseeSession.get();
      if (!franIdResponse.data.hasOwnProperty('data')) return;

      if (franIdResponse) {
        try {
          const { franchiseeId } = franIdResponse?.data?.data;
          await axios
            .post('/api/get-locations/', { ids: [franchiseeId] })
            .then(({ data }) => {
              if (data.data.length) {
                const currentFranchisee = camelcaseKeys(data.data[0]);
                dispatchFranchisee({
                  type: REFRESH,
                  payload: currentFranchisee,
                });
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      franchiseeSession.set(franchisee.id);
    }
  };

  useEffect(() => {
    refreshFranchisee();
  }, []);

  return (
    <FranchiseeContext.Provider value={{ franchisee, dispatchFranchisee }}>
      {children}
    </FranchiseeContext.Provider>
  );
};

FranchiseeProvider.defaultProps = {
  franchiseeValue: defaultFranchisee,
};

const useFranchiseeContext = (): FranchiseeContextHook =>
  useContext(FranchiseeContext);

export {
  FranchiseeContext as default,
  FranchiseeProvider,
  useFranchiseeContext,
  INIT,
  CLEAR,
  REFRESH,
  REPLACE,
};
