/* eslint-disable import/prefer-default-export */
import statesObject from '../data/states_object.json';

export function validateEmail(email) : boolean {
  // eslint-disable-next-line no-control-regex
  const re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
  return re.test(email);
}

export function validatePhone(phoneArg : string) : boolean {
  // console.log("The original phone number is: ", phoneArg);
  // sanitize phone number;
  const phoneNumber = phoneArg.replace(/\D+/gi, '');

  //  must be all digits
  if (/\D+/gi.test(phoneNumber)) return false;

  // check length and first digit;
  const isValid = (phoneNumber.length === 10 || (phoneNumber.length === 11 && phoneNumber.slice(0, 1) === '1'));

  return isValid;
}

export function validateZipCode(zipCode : string) : boolean {
  const zipCodeRegex = /^\d{5}$/;
  return zipCodeRegex.test(zipCode);
}

export function validateUSState(usState : string) : boolean {
  // check if the provided string is one of the provided state codes.
  // includes DC, puerto rico, etc.
  const usStates : Set<string> = new Set(Object.keys(statesObject));
  return usStates.has(usState);
}

export function validateAddressStringField(addressString: string) {
  const addressLineRegex = /^[A-Za-z0-9'.\-\s,#]+$/;

  return addressLineRegex.test(addressString);
}

export function validateCreditCardFormat(cardNumber: string) {
  const cardNumberRegex = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
  return cardNumberRegex.test(cardNumber);
}
