/**
 * Sticky CTA until it hits the footer
 */

const ScrollOut = require('scroll-out');

const stickyScroll = () => {
  /*
    Types must be set to HTMLElement.
    Element does not allow for the use of dataset or style, and fails both linting and jest
  */
  const stickyCta: HTMLElement = document.querySelector('.sticky-cta');
  const footer: HTMLElement = document.querySelector('.footer');
  const header: HTMLElement = document.querySelector('.header');

  // content drawer and store-picker;
  const contentDrawer: HTMLElement = document.querySelector('.content-drawer');
  const drawerScrollWrapper: HTMLElement =
    contentDrawer.querySelector('.scroll-wrapper');

  if (!stickyCta || !footer) {
    return;
  }

  // When it becomes "sticky"
  const stick = () => {
    // Stick, but only if the footer isn't "in"
    if (footer.dataset.scroll === 'in') {
      return;
    }

    stickyCta.classList.add('sticky-cta--stuck');
    footer.style.marginTop = `${stickyCta.clientHeight}px`;

    if (contentDrawer && drawerScrollWrapper) {
      drawerScrollWrapper.style.height = `calc(100vh - (var(--header-height) * 2) - ${footer.style.marginTop})`;
    }

    const toastWrapper: NodeList = document.querySelectorAll('.toast-wrapper');

    // console.log(toastWrapper);

    if (toastWrapper.length) {
      toastWrapper.forEach((wrapper: HTMLElement) => {
        // eslint-disable-next-line no-param-reassign
        wrapper.style.top = 'calc(var(--header-height) + 15px)';
      });
    }
  };

  // When it becomes "unsticky"
  const unstick = () => {
    stickyCta.classList.remove('sticky-cta--stuck');
    footer.style.marginTop = '0';

    if (contentDrawer && drawerScrollWrapper) {
      // console.log('Current wrapper height', drawerScrollWrapper.style.height);
      drawerScrollWrapper.style.height =
        'calc(100vh - (var(--header-height) * 2))';
      // console.log('New wrapper height', drawerScrollWrapper.style.height);
    }

    const toastWrapper: NodeList = document.querySelectorAll('.toast-wrapper');

    if (toastWrapper.length) {
      toastWrapper.forEach((wrapper: HTMLElement) => {
        // eslint-disable-next-line no-param-reassign
        wrapper.style.top = '15px';
      });
    }
  };

  // Sticks until it hits the footer
  ScrollOut({
    targets: '.footer',
    onShown: () => unstick(),
    onHidden: () => stick(),
  });

  ScrollOut({
    targets: header,
    offset: 1,
    onShown: () => stick(),
    onHidden: () => unstick(),
    onChange: () =>
      document.body.style.setProperty(
        '--header-height',
        `${header.clientHeight + 16}px`
      ),
  });

  // Hide when virtual keyboard is visible
  window.addEventListener('keyboardFocus', () => {
    unstick();
  });

  // Show when virtual keyboard blurs
  window.addEventListener('keyboardBlur', () => {
    stick();
  });
};

export default stickyScroll;
