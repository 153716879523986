import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import Header from '../Header';
import stickyScroll from './stickyScroll';

import OpenGraphBoilerPlate from '../OpenGraphBoilerPlate';

// dynamic imports
const StickyCta = dynamic(() => import('../StickyCta'));
const Footer = dynamic(() => import('../Footer'));

interface LayoutProps {
  // add layout props here
  children: React.ReactNode;
  booking?: boolean;
  alternateFooter?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, booking, alternateFooter }: LayoutProps) => {
  useEffect(stickyScroll, []);

  return (
    <>
      <Header />
      <OpenGraphBoilerPlate />
      <main>{children}</main>
      {!booking && <StickyCta />}
      {alternateFooter ? <Footer className='footer-white-bg' /> : <Footer />}
    </>
  );
};

Layout.defaultProps = {
  booking: false,
};

export default Layout;
