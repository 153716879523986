import React, { useState, useEffect } from 'react';
// import styles from './styles'
// import TwoMaidsLogo from '../TwoMaidsLogo';

interface ContentDrawerProps {
  // add ContentDrawer props here
  children: React.ReactNode;
  className?: string;
  show?: boolean;
  onClose: Function;
}

const ContentDrawer: React.FC<ContentDrawerProps> = ({
  className,
  children,
  show,
  onClose,
}: ContentDrawerProps) => {
  const [visible, setVisible] = useState(null);

  useEffect(() => {
    setVisible(show);
    show ? document.body.style.overflowY = 'hidden' : document.body.style.overflowY = 'auto';
    // show ? document.body.style.paddingRight = '12px' : document.body.style.paddingRight = '0';
    // show ? document.getElementById('header').style.paddingRight = '12px' : document.getElementById('header').style.paddingRight = '0px'
  }, [show]);

  return (
    <>
      <div
        className={`content-drawer ${className ?? ''} ${visible ? 'active' : ''}`}
        style={{background: 'var(--light-pink)'}}
      >
        {children}
      </div>
    </>
  );
};

ContentDrawer.defaultProps = {
  // add ContentDrawer default props here
  className: null,
  show: false,
};

export default ContentDrawer;
