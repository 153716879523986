import axios from 'axios';

const franchiseeRequest = axios.create({
  validateStatus: (status) => status >= 200 && status < 500,
});

const getFranchiseeSession = async () => {
  const response = await franchiseeRequest.get('/api/franchisee-session/');

  return response;
};

const setFranchiseeSession = async (franchiseeId : number) => {
  const response = await franchiseeRequest.post('/api/franchisee-session/', { franchiseeId });

  return response;
};

const clearFranchiseeSession = async () => {
  const response = await franchiseeRequest.delete('/api/franchisee-session/', {});
  return response;
};

const franchiseeSession = ({
  get: getFranchiseeSession,
  set: setFranchiseeSession,
  clear: clearFranchiseeSession,
});

export default franchiseeSession;
