import React, { useMemo } from 'react';
import formatPhoneNumber from './formatPhoneNumber';

interface PhoneLinkProps{
  // add phone link props here
  number : string;
  className? : string;
  children?: React.ReactNode;

}

const PhoneLink: React.FC<PhoneLinkProps> = React.memo(({
  number,
  className,
  children,
}: PhoneLinkProps) => {
  const phoneNumber = useMemo(() => {
    const value = number;
    return formatPhoneNumber(value);
  }, [number]);

  return (
    <a href={`tel:+1${phoneNumber}`} style={{ whiteSpace: 'nowrap' }} className={className || ''}>
      {children || phoneNumber}
    </a>
  );
});

PhoneLink.defaultProps = {
  className: null,
  children: null,
};

export default PhoneLink;
