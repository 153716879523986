/* eslint-disable no-param-reassign */
import { Draft } from 'immer';
import { FranchiseeState } from './franchisee.types';
import franchiseeSession from './franchiseeSession';
import defaultFranchisee from './defaultFranchisee.json';
// import * as Sentry from '@sentry/node';

/* Action Type Constants */
export const REFRESH = 'REFRESH';
export const INIT = 'INIT';
export const REPLACE = 'REPLACE';
export const CLEAR = 'CLEAR';

type ActionType = typeof REFRESH
  | typeof INIT
  | typeof REPLACE
  | typeof CLEAR;

export type FranchiseeAction = {
  type: ActionType;
  payload: Partial<FranchiseeState>;
  /* add extra franchisee action fields below */
}

function franchiseeReducer(
  draft : Draft<FranchiseeState>,
  action : FranchiseeAction,
) {
  switch (action.type) {
    case REFRESH:
    case INIT:
      Object.keys(action.payload)
        .forEach((key) => {
          draft[key] = action.payload[key];
        });
      franchiseeSession.set(action.payload.id);
      return draft;

    case REPLACE:
      Object.keys(action.payload)
        .forEach((key) => {
          draft[key] = action.payload[key];
        });
      // console.log(action.payload);
      franchiseeSession.set(action.payload.id);
      return draft;

    case CLEAR:
      Object.keys(defaultFranchisee)
        .forEach((key) => {
          draft[key] = defaultFranchisee[key];
        });
      franchiseeSession.clear();
      return draft;

    default:
      return draft;
  }
}

export default franchiseeReducer;
