import React from 'react';
import Head from 'next/head';
import { config, dom } from '@fortawesome/fontawesome-svg-core';

import { useRouter } from 'next/router';
import { pink500 } from '../../ui/colors';
import openGraph from '../../data/openGraph.json';

interface OpenGraphBoilerPlateProps {
  // add open graph boiler plate props here
}

config.autoAddCss = false;

const OpenGraphBoilerPlate: React.FC<OpenGraphBoilerPlateProps> = () => {
  const router = useRouter();
  // if router is unavailable, default to the base url
  const canonicalURL = `https://twomaidscleaning.com${
    router?.asPath && router.asPath
  }`;
  return (
    <Head>
      <link rel='manifest' href='/manifest.webmanifest' />
      {/* <link rel="preconnect" href="https://fonts.gstatic.com" key="gstatic-preconnect" />
      <link rel="dns-prefetch" href="https://fonts.gstatic.com" key="gstatic-prefetch" /> */}

      <link
        rel='preconnect'
        href='https://res.cloudinary.com'
        key='cloudinary-preconnect'
      />
      <link
        rel='dns-prefetch'
        href='https://res.cloudinary.com'
        key='cloudinary-prefetch'
      />

      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/favicons/apple-touch-icon.png'
        key='apple-icon'
      />
      <link
        rel='icon'
        sizes='32x32'
        href='/favicons/favicon-32x32.png'
        key='icon-32'
      />
      <link
        rel='icon'
        sizes='16x16'
        href='/favicons/favicon-16x16.png'
        key='icon-16'
      />
      <meta
        key='keywords'
        name='keywords'
        content={openGraph.keywords.join(', ')}
      />

      <meta
        key='apple-mobile-web-app-title'
        name='apple-mobile-web-app-title'
        content={openGraph.title}
      />
      <meta
        key='application-name'
        name='application-name'
        content='Two Maids '
      />
      <meta
        key='og:site_name'
        property='og:site_name'
        content={openGraph.title}
      />
      <meta
        key='twitter:title'
        property='twitter:title'
        content={openGraph.title}
      />

      <meta
        key='description'
        property='description'
        name='description'
        content={openGraph.description}
      />

      <meta
        key='og:description'
        property='og:description'
        content={openGraph.description}
      />

      <meta
        key='twitter:description'
        property='twitter:description'
        content={openGraph.description}
      />

      <meta key='og:type' property='og:type' content='website' />
      <meta
        key='og:locale'
        name='og:locale'
        property='og:locale'
        content='en_US'
      />

      <meta
        key='msapplication-TileColor'
        name='msapplication-TileColor'
        content={pink500}
      />
      <meta key='pink' name='msapplication-TileColor' content={pink500} />
      <meta
        key='og:image'
        name='og:image'
        property='og:image'
        content={openGraph.image}
      />

      <link href={canonicalURL} rel='canonical' />
      <meta content={canonicalURL} property='og:url' key='og:url' />
      <meta key='twitter:card' name='twitter:card' content='summary' />
      <style>{dom.css()}</style>
    </Head>
  );
};

export default OpenGraphBoilerPlate;
