/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
// import Link from 'next/link';
import { Button, Card, Modal, Row } from 'react-bootstrap';
import { REPLACE, useFranchiseeContext } from '../../context/Franchisee';
import { FranchiseeState } from '../../context/Franchisee/franchisee.types';
import PhoneLink from '../PhoneLink';
// import styles from './styles'
// import * as Sentry from '@sentry/node';
import * as Sentry from '@sentry/node';
import axios, { AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import leadSession from '../../context/Lead/leadSession';
import {
  setFranchiseID,
  setResetStore,
  setZipCode
} from '../../features/user/userSlice';
import ZipCodeModal from '../ZipCodeModal';

interface StorePickerCardProps {
  // add store picker card props here
  value: FranchiseeState;
  onSelect?: Function;
  source: string;
  bookingSelectFranchise?: Function;
  zipCodeInput;
}

const StorePickerCard: React.FC<StorePickerCardProps> = ({
  value,
  onSelect,
  source,
  bookingSelectFranchise,
  zipCodeInput,
}: StorePickerCardProps) => {
  const { dispatchFranchisee } = useFranchiseeContext();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchFranchiseeList, setSearchFranchiseeList] = useState<
    Array<FranchiseeState>
  >([]);
  const {
    id,
    name,
    phonePrimary,
    addressLineOne,
    city,
    state,
    zipCode,
    slug,
    latitude,
    longitude,
  } = value;

  const router = useRouter();
  const dispatch = useDispatch();

  function distance(lat1, lon1, lat2, lon2, unit) {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == 'K') {
        dist = dist * 1.609344;
      }
      if (unit == 'N') {
        dist = dist * 0.8684;
      }
      return dist;
    }
  }
  const checkZipCode = async () => {
    // Prevent popup from occuring twice
    try {
      const userLocationChangeData = await axios.get(
        '/api/user-location-change-session/'
      );
      // console.log(userLocationChangeData.data.data);
      if (userLocationChangeData.data.data.userLocationChange) {
        replaceFranchisee();
        onSelect();
        return;
      }
    } catch (error) {
      // console.log(response);
      // console.log(error);
      Sentry.captureException(error);
    }

    try {
      const userLocationData = await axios.get('/api/user-location-session/');

      const zip_code = userLocationData.data.data.userLocation.zipCode;

      const userLongitude =
        userLocationData.data?.data?.userLocation?.longitude;
      const userLatitude = userLocationData.data?.data?.userLocation?.latitude;
      if (userLongitude === 0) return;

      let distanceBetweenUserAndStore = distance(
        userLatitude,
        userLongitude,
        latitude,
        longitude,
        'M'
      );
      // console.log({ userLatitude });
      // console.log({ userLongitude });
      // console.log({ latitude });
      // console.log({ longitude });

      // console.log({ value });
      // console.log({ distanceBetweenUserAndStore });
      // console.log({ distanceFunction });
      if (distanceBetweenUserAndStore < 120) {
        replaceFranchisee();
        onSelect();
        return;
      }

      const data = await axios.post('/api/get-locations/', {
        zipCode: zip_code,
      });
      const result = data.data.data;
      let cityMatched = false;

      if (result === undefined || result.length === 0) {
        replaceFranchisee();
        onSelect();
        return;
      }
      for (let franchisee of result) {
        if (franchisee.slug === slug) {
          cityMatched = true;
        }
      }
      if (!cityMatched) {
        const resultStores = result.map((franchisee) =>
          camelcaseKeys(franchisee)
        );
        setSearchFranchiseeList(resultStores);
        setShowModal(true);
        const response: AxiosResponse = await axios.post(
          '/api/user-location-change-session/',
          {
            userLocationChange: true,
          }
        );
      } else {
        replaceFranchisee();
        onSelect();
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      replaceFranchisee();
      onSelect();
    }
  };

  const replaceFranchisee = () => {
    dispatchFranchisee({ type: REPLACE, payload: value });
    sessionStorage.setItem('selectedStore', slug);
    // onSelect();
    // Makes the currentSlug a string without using toString()

    axios
      .post('/api/search-zip-code', { searchZipCode: zipCodeInput })
      .then((result) => {
        dispatch(setZipCode(zipCodeInput));
      })
      .catch((e) => console.log(e));

    if (source === 'booking') {
      bookingSelectFranchise();
    }
    if (source === 'storepicker') {
      axios
        .delete('/api/search-zip-code')
        .then((result) => {})
        .catch((e) => console.log(e));
    }

    let currentSlug = '' + router.query.franchiseeSlug;
    if (router.pathname === '/') {
      router.push(`/${slug}`);
    } else if (router.pathname === '/about') {
      router.push(`/${slug}/about/`);
    } else if (router.pathname === '/blog/page/[pageNumber]') {
      router.push(`/${slug}/blog/page/1`);
    } else if (router.pathname === `/blog/[uid]`) {
      router.push(`/${slug}/blog/page/1`);
    }

// Falls back to the home page -- This is not perfect...Test more --
    else if (router.pathname === '/[franchiseeSlug]/local-content/[uid]') {
      router.push('/');
    } else if (router.pathname === '/reviews') {
      router.push(`/${slug}/reviews/`);
    } else if (router.pathname === '/contact') {
      router.push(`/${slug}/contact/`);
    }

    if (router.pathname === '/[franchiseeSlug]/blog/[uid]') {
      router.push(`/${slug}/blog/page/1/`);
    } else {
      let newPath = router.asPath.replace(currentSlug, slug);
      router.push(newPath);
    }
  };

  return (
    <>
      <Card bsPrefix='store-picker-card' key={id}>
        <Card.Body className='p-3'>
          {/* <Link
          href={`/${router.asPath}/`}
          // href={`/${slug}/`}
          passHref
          prefetch={false}
        > */}
          <a
            className='store-link'
            style={{ cursor: 'pointer' }}
            onClick={replaceFranchisee}
          >
            <h5 className='store-picker-store-title mb-1'>
              {name}, {state}
            </h5>
          </a>
          {/* </Link> */}
          <Row
            className='px-3 mb-2'
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {addressLineOne}
            <br />
            {city}, {state} {zipCode}
            <br />
            <PhoneLink number={phonePrimary} />
          </Row>
          <Row className='justify-content-between align-items-center px-3'>
            <Button onClick={replaceFranchisee} className='store-picker-button'>
              Set as Store
            </Button>
          </Row>
        </Card.Body>
      </Card>
      {showModal && (
        <ZipCodeModal
          franchisees={searchFranchiseeList}
          setModalValue={showModal}
          selectedStore={value}
        />
      )}
    </>
  );
};

StorePickerCard.defaultProps = {
  // add store picker card default props here
};

export default StorePickerCard;
