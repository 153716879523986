import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
// import { useRouter } from 'next/router';
import {
  faChevronLeft,
  faSearch,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cleave from 'cleave.js/react';
import { Card, InputGroup, Modal, Row } from 'react-bootstrap';
import MoonLoader from 'react-spinners/MoonLoader';
// import { useRouter } from 'next/router';
import * as Sentry from '@sentry/node';
import camelcaseKeys from 'camelcase-keys';
import { useFranchiseeContext } from '../../context/Franchisee';
import { FranchiseeState } from '../../context/Franchisee/franchisee.types';
// import { pink500 } from '../../ui/colors';
import { validateZipCode } from '../../utils/stringValidation';
import StorePickerCard from '../StorePickerCard';
// import Gataware from '../../SDKs/Gataware';
import axios, { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { setFranchiseID } from '../../features/user/userSlice';

// import styles from './styles'

interface BookingStorePickerTypes {
  showBookingModal?: boolean;
  bookingModalOrigin?: string;
  handleShowStorePicker?: any;
  handleBookingModalZipCode?: any;
  bookingModalZipCode?: string;
  zipInputValue?: any;
}

const BookingStorePicker: React.FC<BookingStorePickerTypes> = ({
  showBookingModal = false,
  bookingModalOrigin,
  handleShowStorePicker,
  handleBookingModalZipCode,
  bookingModalZipCode,
  zipInputValue,
}: BookingStorePickerTypes) => {
  const { franchisee } = useFranchiseeContext();
  const [searchString, setSearchString] = useState<string>(zipInputValue || '');
  const [loading, setLoading] = useState<boolean>(false);
  const [franchiseeList, setFranchiseeList] = useState<Array<FranchiseeState>>(
    []
  );
  const [errorPage, setErrorPage] = useState<boolean>(false);
  const [networkError, setNetworkError] = useState<boolean>(false);
  const [disableSearch, setDisableSearch] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(showBookingModal);
  const [ipStoreCount, setIpStoreCount] = useState<number>(0);
  const [userQuestion, setUserQuestion] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);
  const dispatch = useDispatch();

  const firstRender = useRef(false);
  const secondRender = useRef(false);

  useEffect(() => {
    // setSearchString(bookingModalZipCode);
    if (zipInputValue?.length === 5) {
      storeSearch();
    }
  }, [zipInputValue]);

  const userRefTest = useRef(true);

  // const router = useRouter();
  function distance(lat1, lon1, lat2, lon2, unit) {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      const radlat1 = (Math.PI * lat1) / 180;
      const radlat2 = (Math.PI * lat2) / 180;
      const theta = lon1 - lon2;
      const radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == 'K') {
        dist = dist * 1.609344;
      }
      if (unit == 'N') {
        dist = dist * 0.8684;
      }
      return dist;
    }
  }
  const checkForCookieZipCode = async () => {
    const zipCodeData = await axios.get('/api/search-zip-code/');
    console.log('franchisee id', franchisee.id);

    if (
      zipCodeData.data.message.includes('Error') ||
      franchisee.slug === 'home'
    )
      // setShowModal(true);
      console.log('showmodal');
    else {
      setShowModal(false);
      // checkZipCode();
    }
  };

  const getLocationsFromIp = async () => {
    try {
      const userLocationData = await axios.get('/api/user-location-session/');
      const zip_code = userLocationData.data?.data?.userLocation?.zipCode;
      if (!zip_code) return;
      const data = await axios.post('/api/get-locations/', {
        zipCode: zip_code,
      });
      return data.data.data;
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      return null;
    }
  };

  const getLocationsFromSearch = async () => {
    try {
      const { data, status }: AxiosResponse = await axios.post(
        '/api/get-franchisee-by-zone-zip-code/',
        {
          zipCode: bookingModalZipCode ?? searchString,
        }
      );
      // axios
      //   .post('/api/search-zip-code', { searchZipCode: searchString })
      //   .then((result) => console.log(result.data))
      //   .catch((e) => console.log(e));
      if (status === 200) {
        // console.log('data', data);
        if (data.data.length === 0) {
          const data = await axios.post('/api/get-locations/', {
            zipCode: bookingModalZipCode ?? searchString,
          });

          return data.data;
        } else {
          return data;
        }
      } else if (status === 502) {
        setLoading(false);
        setNetworkError(true);
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      return null;
    }
  };

  const storeSearch = async () => {
    let franchiseArray = [];
    try {
      setLoading(true);
      setDisableSearch(true);
      const searchResult = await getLocationsFromSearch();
      // Removed the ipResult to work on getting location by zone first.
      // const ipResult = await getLocationsFromIp();
      userRefTest.current = false;
      if (
        !searchResult.hasOwnProperty('data') ||
        searchResult.data.length < 1
      ) {
        setFranchiseeList([]);
      } else {
        const franchiseeData = searchResult.data.map((franchisee) =>
          camelcaseKeys(franchisee)
        );
        franchiseArray = franchiseeData;
      }
      // if (ipResult !== undefined && ipResult.length !== 0) {
      //   const ipResultData = ipResult.map((franchisee) =>
      //     camelcaseKeys(franchisee)
      //   );
      //   franchiseArray = [...franchiseArray, ...ipResultData];
      //   setIpStoreCount(ipResultData.length);
      //   // Remove duplicates
      //   franchiseArray = franchiseArray.filter(
      //     (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      //   );
      // }
      if (franchiseArray.length === 0) {
        setErrorPage(true);
      } else {
        setErrorPage(false);
      }
      setFranchiseeList(franchiseArray);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoading(false);
      setDisableSearch(false);
    }
  };

  async function checkZipCode() {
    try {
      const userLocationChangeData = await axios.get(
        '/api/user-location-change-session/'
      );
      // console.log(userLocationChangeData.data.data);
      if (userLocationChangeData.data.data.userLocationChange) {
        return;
      }
    } catch (error) {
      // console.log(response);
      // console.log(error);
      Sentry.captureException(error);
    }
    try {
      const userLocationData = await axios.get('/api/user-location-session/');
      const zip_code = userLocationData.data?.data?.userLocation?.zipCode;
      const userLongitude =
        userLocationData.data?.data?.userLocation?.longitude;
      const userLatitude = userLocationData.data?.data?.userLocation?.latitude;

      // check if longitude exists
      if (userLongitude === 0) return;
      // console.log({ franchisee });

      const distanceBetweenUserAndStore = distance(
        userLatitude,
        userLongitude,
        franchisee.latitude,
        franchisee.longitude,
        'M'
      );
      if (distanceBetweenUserAndStore < 100) {
        return;
      }
      const data = await axios.post('/api/get-locations/', {
        zipCode: zip_code,
      });
      const result = data.data.data;
      let cityMatched = false;

      for (const franchiseesFromResult of result) {
        if (franchiseesFromResult.slug === franchisee.slug) {
          cityMatched = true;
        }
      }
      if (!cityMatched) {
        const resultStores = result.map((franchisee) =>
          camelcaseKeys(franchisee)
        );
        setFranchiseeList(resultStores);
        setIpStoreCount(resultStores.length);
        setShowModal(true);
        userRefTest.current = true;
        const response: AxiosResponse = await axios.post(
          '/api/user-location-change-session/',
          {
            userLocationChange: true,
          }
        );
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  }

  function bookingSelectFranchise() {
    const { body } = document;
    // setShowModal(false);
    handleShowStorePicker(searchString, false);
    setTimeout(() => {
      body.style.overflow = 'auto';
      body.style.padding = '0px';
    }, 500);
  }

  useEffect(() => {
    if (searchString.length === 5) {
      // handleBookingModalZipCode(searchString);
    }
    setDisableSearch(!validateZipCode(searchString));
  }, [searchString]);

  if (!showModal) return null;

  const sortedFranchiseeList = franchiseeList
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      {/* {!loading && ( */}
      <>
        <Modal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          backdrop='static'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header className='d-flex justify-content-between align-items-center'>
            <Modal.Title className='store-picker-modal-title'>
              {userRefTest.current === true
                ? 'Select a Store'
                : 'Please enter your zip code'}
            </Modal.Title>
            {/* {bookingModalOrigin === 'book' && ( */}
            <div style={{ color: 'white' }}>
              <button
                onClick={() => {
                  setShowModal(false);
                  handleShowStorePicker?.(searchString, false);
                }}
                className='btn btn-sm btn-primary'
              >
                Close
              </button>
            </div>
            {/* )} */}
            {/* {bookingModalOrigin !== 'book' && (
                <Link href='/'>
                  <a
                    onClick={() => setShowModal(!showModal)}
                    title='home'
                    className='btn btn-sm btn-primary'
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                    {'   '}
                    Back To Home
                  </a>
                </Link>
              )}{' '} */}
          </Modal.Header>
          <Modal.Body className='px-0'>
            <div className='px-3'>
              <p>This helps us determine if you're in our service area.</p>
              <InputGroup>
                <Cleave
                  className='form-control'
                  placeholder='Zip Code'
                  options={{ numericOnly: true }}
                  maxLength={5}
                  value={searchString}
                  autoComplete='postal-code'
                  type='tel'
                  onChange={(e) => {
                    setSearchString(e.currentTarget.value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter' && searchString.length === 5) {
                      storeSearch();
                      userRefTest.current = false;
                    }
                  }}
                />
                <InputGroup.Append>
                  <button
                    type='button'
                    className='btn btn-primary d-flex justify-content-between align-items-center  store-picker-button'
                    title='search'
                    disabled={disableSearch}
                    onMouseLeave={() => setHover(false)}
                    onMouseEnter={() => setHover(true)}
                    onClick={storeSearch}
                  >
                    <span className='d-flex d-sm-none search-input'>
                      Search
                    </span>
                    <span style={{ minWidth: 30 }}>
                      {loading ? (
                        <MoonLoader
                          color={hover ? '#cf2680' : '#fff'}
                          size={16}
                          loading
                        />
                      ) : (
                        <FontAwesomeIcon icon={faSearch} />
                      )}
                    </span>
                    <span className='d-none d-sm-flex'>Find Your Store</span>
                  </button>
                </InputGroup.Append>
              </InputGroup>
            </div>
            {sortedFranchiseeList.length > 0 && (
              <div className='scroll-wrapper modal-scroll'>
                {sortedFranchiseeList.map((fran, index) => (
                  <div
                    style={{
                      background: index > ipStoreCount ? '#e1e1e13d' : '',
                    }}
                    key={fran.id}
                  >
                    <StorePickerCard
                      key={fran.id}
                      value={fran}
                      source='booking'
                      bookingSelectFranchise={() => bookingSelectFranchise()}
                      zipCodeInput={searchString}
                    />
                  </div>
                ))}
                {/* {franchisee.name !== 'Home' && (
                <StorePickerCard
                  key={franchisee.id}
                  value={franchisee}
                  source='booking'
                  bookingSelectFranchise={() => bookingSelectFranchise()}
                />
              )} */}
              </div>
            )}
            {networkError && (
              <Card bsPrefix='store-picker-card'>
                <Card.Body className='p-3 '>
                  <h5 className='h5 mb-2'>502 Bad Gateway</h5>
                  <Row className='px-3 mb-2'>
                    <p>
                      There seems to be a network issue. This request cannot be
                      completed at this time.
                    </p>
                    <br />
                    <Link href='/' passHref>
                      <a className='btn btn-block btn-blue mt-3'>
                        Click here to go back to the home page{' '}
                      </a>
                    </Link>
                  </Row>
                </Card.Body>
              </Card>
            )}
            {errorPage === true && (
              <Card bsPrefix='store-picker-card'>
                <Card.Body className='p-3 '>
                  <h5 className='h5 mb-2'>No Location Found</h5>
                  <Row className='px-3 mb-2'>
                    <p>
                      Unfortunately, your zip code is not currently in our
                      service area. We are growing and hope to service your area
                      in the future, so please check back with us.
                    </p>
                    <br />
                    <Link href='/' passHref>
                      <a className='btn btn-block btn-blue mt-3'>
                        Click here to go back to the home page{' '}
                      </a>
                    </Link>
                  </Row>
                </Card.Body>
              </Card>
            )}
          </Modal.Body>
        </Modal>
      </>
      {/* )} */}
    </>
  );
};

export const MemoizedBookingStorePicker = React.memo(BookingStorePicker);
export default BookingStorePicker;
