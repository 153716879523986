/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
// import Link from 'next/link';
import { Card, Row, Button, Modal } from 'react-bootstrap';
import PhoneLink from '../PhoneLink';
import { useFranchiseeContext, REPLACE } from '../../context/Franchisee';
import * as Sentry from '@sentry/node';
import { useRouter } from 'next/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FranchiseeState } from '../../context/Franchisee/franchisee.types';
import axios from 'axios';
interface ZipCodeModalProps {
  franchisees: Array<FranchiseeState>;
  setModalValue: boolean;
  selectedStore: any;
}

const ZipCodeModal: React.FC<ZipCodeModalProps> = ({
  franchisees,
  setModalValue,
  selectedStore,
}: ZipCodeModalProps) => {
  const [showModal, setShowModal] = useState<boolean>(setModalValue);

  const { dispatchFranchisee } = useFranchiseeContext();

  const router = useRouter();

  const replaceFranchisee = (franchisee, selectedContinue) => {
    let slug = '';
    if (selectedContinue) {
      dispatchFranchisee({
        type: REPLACE,
        payload: selectedStore,
      });
      slug = selectedStore.slug;
    } else {
      dispatchFranchisee({
        type: REPLACE,
        payload: franchisee,
      });
      slug = franchisee.slug;
    }
    axios
      .delete('/api/search-zip-code')
      .then((result) => {})
      .catch((e) => console.log(e));
    // Makes the currentSlug a string without using toString()
    let currentSlug = '' + router.query.franchiseeSlug;
    if (router.pathname === '/') {
      router.push(`/${slug}`);
    } else if (router.pathname === '/about') {
      router.push(`/${slug}/about/`);
    } else if (router.pathname === '/blog/page/[pageNumber]') {
      router.push(`/${slug}/blog/page/1`);
    } else if (router.pathname === `/blog/[uid]`) {
      router.push(`/${slug}/blog/page/1`);
    }
    // Falls back to the home page -- This is not perfect...Test more --
    else if (router.pathname === '/[franchiseeSlug]/local-content/[uid]') {
      router.push('/');
    } else if (router.pathname === '/reviews') {
      router.push(`/${slug}/reviews/`);
    } else if (router.pathname === '/contact') {
      router.push(`/${slug}/contact/`);
    }
    // Redirect to the individual franchise website funnel if the slug is not in the list below and the path is /book
    if (router.pathname === '/book') {
      return;
    } else if (router.pathname === '/book') {
      try {
        // setTimeout(function () {
        //   document.location.href = `https://${franchisee.url}.com/billing/info/`;
        // }, 80);
      } catch (err) {
        Sentry.captureException(err);
      }
    }
    if (router.pathname === '/[franchiseeSlug]/blog/[uid]') {
      router.push(`/${slug}/blog/page/1/`);
    } else {
      let newPath = router.asPath.replace(currentSlug, slug);
      router.push(newPath);
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header className='d-flex justify-content-between align-items-center'>
        <Modal.Title>Have you selected the correct location?</Modal.Title>
        <a
          onClick={() => setShowModal(false)}
          title='home'
          className='btn btn-sm btn-primary'
          style={{ color: '#fff', display: 'flex', alignItems: 'center' }}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ marginRight: '3px' }}
          />
          Close
        </a>
      </Modal.Header>
      <Modal.Body className='px-0' style={{ padding: '0px' }}>
        {franchisees.length > 0 && (
          <div
            className='scroll-wrapper modal-scroll'
            style={{ maxHeight: '350px', margin: '0px' }}
          >
            {franchisees.map((fran) => (
              <Card bsPrefix='store-picker-card' key={fran.id}>
                <Card.Body
                  className='p-3'
                  style={{ backgroundColor: '#e1e1e13d' }}
                >
                  <a
                    className='store-link'
                    style={{ cursor: 'pointer' }}
                    onClick={() => replaceFranchisee(fran, false)}
                  >
                    <h5 className='h5 mb-2'>
                      {fran.name}, {fran.state}
                    </h5>
                  </a>
                  <Row className='px-3 mb-2'>
                    {fran.addressLineOne}
                    <br />
                    {fran.city}, {fran.state} {fran.zipCode}
                  </Row>
                  <Row className='justify-content-between align-items-center px-3'>
                    <Button
                      variant='outline-primary'
                      onClick={() => replaceFranchisee(fran, false)}
                    >
                      Set as Store
                    </Button>
                    <PhoneLink number={fran.phonePrimary} />
                  </Row>
                </Card.Body>
              </Card>
            ))}
            <Card bsPrefix='store-picker-card' key={selectedStore.id}>
              <Card.Body className='p-3'>
                <a
                  className='store-link'
                  style={{ cursor: 'pointer' }}
                  onClick={() => replaceFranchisee(selectedStore, true)}
                >
                  <h5 className='h5 mb-2'>
                    {selectedStore.name}, {selectedStore.state}
                  </h5>
                </a>
                {/* </Link> */}
                <Row className='px-3 mb-2'>
                  {selectedStore.addressLineOne}
                  <br />
                  {selectedStore.city}, {selectedStore.state}{' '}
                  {selectedStore.zip_code}
                </Row>
                <Row className='justify-content-between align-items-center px-3'>
                  <Button
                    variant='outline-primary'
                    onClick={() => replaceFranchisee(selectedStore, true)}
                  >
                    Set as Store
                  </Button>

                  <PhoneLink number={selectedStore.phonePrimary} />
                </Row>
              </Card.Body>
            </Card>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

ZipCodeModal.defaultProps = {
  // add store picker card default props here
};

export default ZipCodeModal;
